import React, { useEffect, useState, useRef } from 'react';

import Dropdown from '../../components/_comparator/dropdown';
import { states } from '../../../static/assets/data/dealers/api-states';
import { withScriptjs } from 'react-google-maps';
import { useCurrenDevice, DEVICES } from '../../hooks/useCurrentDevice'
import DealersFind from '../../components/svg/dealers/find';

import { GpsButton } from '../../components/buttons/gps_button'

function Stores  ({default_item, handleState, stores, handleStore, handleOver, loading, setSearchValue,handleGps}) {
    const [listStores, setListStores] = useState([]);
    const inputSearch= useRef(null);
    // const activateGpsButton= useRef(null);
    const [device] = useCurrenDevice()

    function capitalizeFLetter(str) { 
        str = str.toLowerCase()
        str = str.charAt(0).toUpperCase() + str.slice(1); 
        return str
      } 

    useEffect(() => {
     
        if(!stores) return;
        let listStores = []
        stores.map(item => {
            return listStores = [...listStores, capitalizeFLetter(item.data.store)];
        })
        const unique = listStores.filter((v, i, a) => a.indexOf(v) === i); // filtrar unicos
        setListStores(unique);
    }, [stores])

    useEffect(() => {
        let autocomplete = new window.google.maps.places.Autocomplete(inputSearch.current, {
            types: ['(regions)'],
            componentRestrictions: { country: 'mx' }
        });

        autocomplete.addListener('place_changed', () => {
            setSearchValue(autocomplete.getPlace())
        })
    })

    // function handlePlacesChanged() {

    // }

    function onGpsClickHandler(){        
        handleGps();
    }
    return (
        <div className="c-dealers-stores">
            <h1 className="c-dealers__title">Distribuidores</h1>
            { device !== DEVICES.mobile &&
                <div className="c-dealers-stores__topbuttons_container">
                    <div className="c-dealers-stores__gpsbutton_container">
                        <GpsButton _section="stores_" _onClick={onGpsClickHandler}></GpsButton>
                    </div>
                    <div className="c-delaers-search">
                                        
                            <div className="c-delaers-search__icon">
                                <DealersFind/>
                            </div>
                            <input
                                ref={inputSearch}
                                type="text"
                                placeholder="Busca tu ciudad..."
                            />                                                   
                    </div>
                </div>
            }
            { device === DEVICES.mobile && 
                <div className="c-dealers-stores__gpsbutton_container">
                    <GpsButton _section="stores_" ></GpsButton>
                </div>
            }
            
            
            <Dropdown items={states} default_item={(default_item) ? default_item.data.title.text: 'Selecciona un estado'} handleItemClick={handleState} type={2} disabled={loading} />
            <ul className="c-dealers-stores__list">
                {
                    // stores && stores.map((store) => <li key={store.uid} onClick={() => handleStore(store)}>{store.data.store}</li>)
                    listStores.length > 0 && !loading ? listStores.map((store, i) => <li key={i} onClick={() => handleStore(store)} onMouseOver={() => handleOver(store)} onMouseLeave={() => handleOver(null)}>{store}</li>) : null
                }
            </ul>
        </div>
    )
}
export const WrappedStores = withScriptjs( Stores);
// export default Stores;
